<template>
    <div class="simple-text">
        <h1 class="block" v-if="block.tag == 'h1'" ref="editableBlock" v-bind="$attrs" v-on="$listeners"></h1>
        <h2 class="block" v-else-if="block.tag == 'h2'" ref="editableBlock" v-bind="$attrs" v-on="$listeners"></h2>
        <h3 class="block" v-else-if="block.tag == 'h3'" ref="editableBlock" v-bind="$attrs" v-on="$listeners"></h3>
        <p class="block" v-else-if="block.tag == 'p'" ref="editableBlock" v-bind="$attrs" v-on="$listeners"></p>
    </div>
</template>


<script>

    export default {
        inheritAttrs: false,
        components: {

        },
        props: {
            block: Object
        },
        watch: {

        },
        data() {
            return { 

            }
        },
        computed: {

        },
        created() { 

        },
        mounted() {

        },
        methods: {
            
        }
    }
</script>